(function() {

//   var mainSliderSelector = '.u-main-slider',
//       mainContentSelector = '.u-main-content',
//       $initialActiveSlide = $('.u-main-slider__item[data-page-url^="' + location.origin + location.pathname + '"]'),
    //   initialActiveSlideIndex = $initialActiveSlide.index(),
    //   sliderOptions = {
    //     infinite: true,
    //     arrows: true,
    //     autoplay: initialActiveSlideIndex === 0,
    //     autoplaySpeed: 4000,
    //     appendArrows: '.u-controls',
    //     prevArrow:'<button class="u-main-slider__prev u-svg-button u-svg-button--style-bordered"><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 62 62" style="enable-background:new 0 0 62 62;" xml:space="preserve"><path class="u-svg-button__background" d="M31,61.5C14.2,61.5,0.5,47.8,0.5,31C0.5,14.2,14.2,0.5,31,0.5c16.8,0,30.5,13.7,30.5,30.5 C61.5,47.8,47.8,61.5,31,61.5z M31,1.5C14.7,1.5,1.5,14.7,1.5,31S14.7,60.5,31,60.5S60.5,47.3,60.5,31S47.3,1.5,31,1.5z"/><path class="u-svg-button__arrow" d="M35,37.2L33.3,39L26,31.5l7.3-7.5l1.7,1.8l-5.6,5.7L35,37.2z"/></svg></button>',
    //     nextArrow: '<button class="u-main-slider__next u-svg-button"><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 62 62" style="enable-background:new 0 0 62 62;" xml:space="preserve"><circle class="u-svg-button__background" cx="31" cy="31" r="30"/><path class="u-svg-button__border" d="M31,61.5C14.2,61.5,0.5,47.8,0.5,31C0.5,14.2,14.2,0.5,31,0.5c16.8,0,30.5,13.7,30.5,30.5	C61.5,47.8,47.8,61.5,31,61.5z M31,1.5C14.7,1.5,1.5,14.7,1.5,31S14.7,60.5,31,60.5S60.5,47.3,60.5,31S47.3,1.5,31,1.5z"/><path class="u-svg-button__arrow" d="M27,37.2l1.7,1.8l7.3-7.5L28.7,24L27,25.8l5.6,5.7L27,37.2z"/></svg></button>'
    //   }
    //   ;

//   function replaceContent($slideItem) {
//       $(document).trigger('destroy-content');
//       $(mainContentSelector).html($slideItem.data("page"));
//       $(document).trigger('content-ready', $slideItem);

//       objectFitImages();
      
//         anime.timeline({
//             easing: 'linear',
//             begin: function() {
//                 $(mainContentSelector).parent().children('.spinner').remove();
//             }
//         })
//         .add({
//             targets: $(mainContentSelector)[0],
//             duration: 200,
//             opacity: [0,1]
//         })
//   }
  
//   function loadContent(slide) {
//       var $slideItem = $(slide),
//           url = $slideItem.data("page-url");

  
//       if(!$slideItem.data("page")) {

//         anime.timeline({
//             easing: 'linear',
//             begin: function() {
//                 $(mainContentSelector).parent().prepend($('<div class="spinner"></div>'));
//             }
//         })
//         .add({
//             targets: $(mainContentSelector)[0],
//             duration: 200,
//             opacity: [1,0]
//         });

//         $(mainContentSelector)

//           $.get(url, function(data) {
//               $slideItem.data("page-content-html", data);

//               var onlyMainContent = data.substring(data.indexOf('<main'), data.indexOf('</main>'));
//               onlyMainContent = onlyMainContent.substring(onlyMainContent.indexOf('>') + 1);

//               $slideItem.data("page", data.indexOf('<html') >= 0 ? onlyMainContent : data);
//               $slideItem.data("page-title", data.indexOf('<html') >= 0 ?  $(data).filter('title').text().trim() : $slideItem.find('.u-teaser__headline').text());
//               replaceContent($slideItem);
//           });
//       }
//       else {
//           replaceContent($slideItem);
//       }
//   }

  $(function() {

    //   var $mainSlider = $(mainSliderSelector),
    //       dontLoadContent = false;

    // if(!$mainSlider) return;

      // init slider
    //   mainSlider = $mainSlider.slick(sliderOptions);

      objectFitImages();
      
      // load content on slide change
    //   $mainSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    //       $nextSlide = $(slick.$slides[nextSlide]).find(".u-main-slider__item");
    //       if(!dontLoadContent) {
    //           loadContent($nextSlide);
    //       } else {
    //           dontLoadContent = false;              
    //         $(document).trigger('destroy-content');
    //         $(document).trigger('content-ready', $nextSlide);
    //       }

    //       $('.u-lang-switcher__item--de .u-lang-switcher__link').attr('href', $nextSlide.data('page-url-de'));
    //       $('.u-lang-switcher__item--en .u-lang-switcher__link').attr('href', $nextSlide.data('page-url-en'));

    //     });
        
    // if(location.pathname.length > 1 && $initialActiveSlide.length > 0) {
    //     dontLoadContent = true;
    //     $mainSlider.slick('slickGoTo', initialActiveSlideIndex, true);
    // } else if($(mainContentSelector).children().length === 0) {
    //     // init first slide content
    //     loadContent($mainSlider.find(".slick-active .u-main-slider__item").eq(0));
    // }
    // else {
    //     // trigger content ready on init
    //     $(document).trigger('content-ready', $mainSlider.find(".slick-active .u-main-slider__item").eq(0));
    // }

    // window.onpopstate = function(e){
    //     $('body').addClass('disable-scroll-event');
    //     if(e.state){
    //         $(window).scrollTop();
    //         document.title = e.state.pageTitle;

    //         var currentId = $(':not(.slick-cloned) > div > .u-main-slider__item[data-page-url^="' + location.origin + location.pathname + '"]').closest('[data-slick-index]').data('slick-index') || 0;
    //         $mainSlider.slick('slickGoTo', currentId, true);
    //     }
    // };

  });
})();

// destroy inView Objects
function destroyInViewOnContentDestroy(inViewObject) {
    $(document).on('destroy-content', function() {
        inViewObject.handlers.enter = [];
        inViewObject.handlers.exit = [];
        inViewObject.current = [];
        inViewObject.elements = [];
    });
}


(function() {

    $(document).ready(function() {

        // var inviewSelectorDefault = '.u-section:not(.u-quote__text, .u-quote__name, .u-quote__title';

        // var inviewDefault = inView(inviewSelectorDefault).on('enter', function(target) {

        //     var $target = $(target),
        //         height = $target.outerHeight(),
        //         lineHeight = parseFloat($target.css('line-height'));

        //     if($target.hasClass('u-inview-complete')) return;

        //     var animation = anime.timeline({
        //         easing: 'easeOutExpo',
        //         begin: function() {
        //             $target.addClass('u-inview-complete');
        //             $target.css({
        //                 'height': height,
        //                 'overflow': 'hidden'
        //             });
        //         },
        //         complete: function() {
        //             $target.css('line-height', '');
        //             $target.css({
        //                 'height': '',
        //                 'overflow': ''
        //             });
        //         }
        //     }).
        //     add({
        //         targets: target,
        //         translateY: ["100%",0],
        //         opacity: [0,1],
        //         // lineHeight: [ lineHeight * 20 + 'px', lineHeight + 'px' ]
        //     });

        // });
        // destroyInViewOnContentDestroy(inviewDefault);
        // $(inviewSelectorDefault).css({
        //     'opacity': 0
        // });

        // var inviewSelectorPictureLeft = '.u-section__content-left .u-picture img';
        // var inviewPictureLeft = inView(inviewSelectorPictureLeft).on('enter', function(target) {
        //     var $target = $(target);

        //     if($target.hasClass('u-inview-complete')) return;

        //     anime.timeline({
        //         easing: 'easeOutExpo',
        //         begin: function() {
        //             $target.addClass('u-inview-complete');
        //         }
        //     }).
        //     add({
        //         targets: target,
        //         translateX: ["-100%",0],
        //         opacity: [0,1]
        //     });
        // });
        // destroyInViewOnContentDestroy(inviewPictureLeft);
        // $(inviewSelectorPictureLeft).css({
        //     'transform': 'translateX(-100%)'
        // });
        // $(inviewSelectorPictureLeft).parent().css({
        //     'overflow': 'hidden'
        // });

        // var inviewSelectorPictureLeft = '.u-section__content-right .u-picture img';
        // var inviewPictureLeft = inView(inviewSelectorPictureLeft).on('enter', function(target) {
        //     var $target = $(target);

        //     if($target.hasClass('u-inview-complete')) return;

        //     anime.timeline({
        //         easing: 'easeOutExpo',
        //         begin: function() {
        //             $target.addClass('u-inview-complete');
        //         }
        //     }).
        //     add({
        //         targets: target,
        //         translateX: ["100%",0],
        //         opacity: [0,1]
        //     });
        // });
        // destroyInViewOnContentDestroy(inviewPictureLeft);
        // $(inviewSelectorPictureLeft).css({
        //     'transform': 'translateX(100%)'
        // });
        // $(inviewSelectorPictureLeft).parent().css({
        //     'overflow': 'hidden'
        // });

        // var inviewSelectorPicture = '.u-section__content:not(.u-section__content-left):not(.u-section__content-right) > figure.u-picture img',
        //     directionToLeft = false;

        // var inviewPicture = inView(inviewSelectorPicture).on('enter', function(target) {
        //     var $target = $(target);

        //     directionToLeft = !directionToLeft;

        //     if($target.hasClass('u-inview-complete')) return;

        //     anime.timeline({
        //         easing: 'easeOutExpo',
        //         begin: function() {
        //             $target.addClass('u-inview-complete');
        //             $target.css({
        //                 'transform': directionToLeft ? 'translateX(100%)' : 'translateX(-100%)'
        //             });
        //         }
        //     }).
        //     add({
        //         targets: target,
        //         translateX: [directionToLeft ? '100%' : '-100%', 0],
        //         opacity: [0,1]
        //     });
        // });
        // destroyInViewOnContentDestroy(inviewPicture);
        // $(inviewSelectorPicture).css({
        //     'opacity': 0
        // });
        // $(inviewSelectorPicture).parent().css({
        //     'overflow': 'hidden'
        // });

    });

})();
(function() {

  function CustomCursor($element, options) {
    var that = this;

      this.$element = $element;
      this.$cursor = null;
      this.movingTimer = null;
      this.options = $.extend({}, {
        $container: null
      }, options);

      this.$middleFinger = null;
      this.$ringFinger = null;
      this.$littleFinger = null;
      this.$pointerFinger = null;
      this.$thumb = null;

      this.animations = {};

      this.paths = {
        middleFinger: {
          full: 'M69,42V9c0-3.3,2.6-6,6-6l0,0c3.3,0,6,2.7,6,6v33',
          small: 'M69,42V32c0-3.3,2.6-6,6-6l0,0c3.3,0,6,2.7,6,6v10'
        },
        ringFinger: {
          full: 'M81,43V14c0-3.3,2.6-6,6-6l0,0c3.3,0,6,2.7,6,6v29',
          small: 'M81,43V36c0-3.3,2.6-6,6-6l0,0c3.3,0,6,2.7,6,6v7'
        },
        littleFinger: {
          full: 'M93,43V23c0-3.3,2.6-6,6-6c3.3,0,6,2.7,6,6v26',
          small: 'M93,43V40c0-3.3,2.6-6,6-6c3.3,0,6,2.7,6,6v9'
        },
        pointerFinger: {
          full: 'M57,49V15c0-3.3,2.6-6,6-6l0,0c3.3,0,6,2.7,6,6V43',
          half: 'M57,49V23c0-3.3,2.6-6,6-6l0,0c3.3,0,6,2.7,6,6V43',
          small: 'M57,49V36c0-3.3,2.6-6,6-6l0,0c3.3,0,6,2.7,6,6V43'
        },
        thumb: {
          full:  'M105,48v19c0,14.8-14.3,25.3-29,24c-6.3-0.6-12.6-3.5-17-8L38,61c-2.4-2.4-2.4-6.6,0-9 l0,0c2.4-2.4,6.6-2.4,9,0 l10,10V46',
          small: 'M105,48v19c0,14.8-14.3,25.3-29,24c-6.3-0.6-12.6-3.5-17-8L45,68c-2.4-2.4-2.4-6.6,0-9 l0,0c2.4-2.4,6.6-2.4,9,0 l3,3V46'
        }
      }

      this.$container = this.options.$container || this.$element;
      
      this.init();
  }

  CustomCursor.prototype.init = function() {
    var that = this;
    
    this.$container.find('.' + this.options.cssClass).remove();
    this.$cursor = $(
      '<svg class="u-custom-cursor" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 151.8 97" style="enable-background:new 0 0 151.8 97;" xml:space="preserve">'
      + '<path class="st0	u-custom-cursor__finger u-custom-cursor__middle-finger" d="' + this.paths.middleFinger.full + '"/>'
      + '<path class="st1 u-custom-cursor__finger u-custom-cursor__ring-finger" d="' + this.paths.ringFinger.full + '"/>'
      + '<path class="st1 u-custom-cursor__finger u-custom-cursor__little-finger" d="' + this.paths.littleFinger.full + '"/>'
      + '<path class="st1 u-custom-cursor__finger u-custom-cursor__pointer-finger" d="' + this.paths.pointerFinger.full + '"/>'
      + '<path class="st2 u-custom-cursor__finger u-custom-cursor__thumb" d="' + this.paths.thumb.full + '"/>'
      + '<polyline class="u-custom-cursor__arrow" points="15,39 4,50 15,61 "/>'
      + '<polyline class="u-custom-cursor__arrow" points="137,61 148,50 137,39 "/>'
      + '</svg>'
    );
    this.$container.append(this.$cursor);

    this.$middleFinger = this.$cursor.find('.u-custom-cursor__middle-finger');
    this.$ringFinger = this.$cursor.find('.u-custom-cursor__ring-finger');
    this.$littleFinger = this.$cursor.find('.u-custom-cursor__little-finger');
    this.$pointerFinger = this.$cursor.find('.u-custom-cursor__pointer-finger');
    this.$thumb = this.$cursor.find('.u-custom-cursor__thumb');

    this.$container.on('mousemove', function(e) {
      that.mousemove(e);
    });

    this.$container.addClass('u-custom-cursor__container');



    this.$container.on('mouseenter', 'button, a', function() {
      that.hover(true);
    });
    
    this.$container.on('mouseleave', 'button, a', function() {
      that.hover(false);
    });

    this.$container.on('mousedown', 'button, a', function() {
      that.click(true);
    });
    
    this.$container.on('mouseup', 'button, a', function() {
      that.click(false);
    });

    this.$container.on('mousedown', function(e) {
      that.grab(true, e);
    });
    
    this.$container.on('mouseup', function(e) {
      that.grab(false, e);
    });
  }

  CustomCursor.prototype.mousemove = function(e) {
    var that = this,
        offset = this.$container.offset();

    this.$cursor.addClass('is-moving');

    this.$cursor.css('transform', 'translate(calc(' + (e.pageX - offset.left) + 'px - 40%), calc(' + (e.pageY - offset.top) + 'px - 20%))');
    this.$cursor.css('transform', 'translateX(' + (e.pageX - offset.left) + 'px) translateX(-40%) translateY(' + (e.pageY - offset.top) + 'px) translateY(-20%)');
  
    clearTimeout(this.movingTimer);
  
    this.movingTimer = setTimeout(function() {
      that.$cursor.removeClass('is-moving');
     }, 300);
  }

  CustomCursor.prototype.hover = function(isMouseOver) {
    var that = this;

    anime.timeline({
      easing: 'linear'
    })
    .add({
        targets: that.$littleFinger[0],
        duration: 200,
        d: isMouseOver ? that.paths.littleFinger.small : that.paths.littleFinger.full
    })
    .add({
        targets: that.$ringFinger[0],
        duration: 200,
        offset: '-=200',
        d: isMouseOver ? that.paths.ringFinger.small : that.paths.ringFinger.full
    })
    .add({
        targets: that.$middleFinger[0],
        duration: 200,
        offset: '-=200',
        d: isMouseOver ? that.paths.middleFinger.small : that.paths.middleFinger.full
    })
    .add({
      targets: that.$pointerFinger[0],
      duration: 200,
      d: that.paths.pointerFinger.full
    });
  }

  CustomCursor.prototype.click = function(isMouseDown) {
    var that = this;

    anime.timeline({
      easing: 'linear'
    })
    .add({
        targets: that.$pointerFinger[0],
        duration: 200,
        d: isMouseDown ? that.paths.pointerFinger.half : that.paths.pointerFinger.full
    });
  }

  CustomCursor.prototype.grab = function(isMouseDown, e) {

    if(!this.options.forceGrabbing) return;

    var that = this;

    anime.timeline({
      easing: 'linear'
    })
    .add({
        targets: that.$littleFinger[0],
        duration: 200,
        d: isMouseDown ? that.paths.littleFinger.small : that.paths.littleFinger.full
    })
    .add({
        targets: that.$ringFinger[0],
        duration: 200,
        offset: '-=200',
        d: isMouseDown ? that.paths.ringFinger.small : that.paths.ringFinger.full
    })
    .add({
        targets: that.$middleFinger[0],
        duration: 200,
        offset: '-=200',
        d: isMouseDown ? that.paths.middleFinger.small : that.paths.middleFinger.full
    })
    .add({
      targets: that.$pointerFinger[0],
      duration: 200,
      offset: '-=200',
      d: isMouseDown ? that.paths.pointerFinger.small : that.paths.pointerFinger.full
    })
    .add({
      targets: that.$thumb[0],
      duration: 200,
      offset: '-=200',
      d: isMouseDown ? that.paths.thumb.small : that.paths.thumb.full
    });
  }

  $.fn.generateCustomCursor = function(options) {
    var $element = $(this);

    if(!$element.data('custom-cursor')) {
      var customCursorObject = new CustomCursor($element, options);
      $element.data('custom-cursor', customCursorObject);
    }
  };

})();
(function () {

  var durations = {
    labelHide: 200,
    linkShow: 150
  };

  function ShareButton($element, options) {
    this.$element = $element;
    this.$toggler = this.$element.find('.u-share-button__toggler');
    this.$shareLinks = this.$element.find('.u-share-button__link');
    this.options = $.extend({}, {

    }, options);

    this.init();
  }

  ShareButton.prototype.init = function () {

    var that = this;

    this.$toggler.on('click', function (e) {
      if (navigator.share) {
        navigator.share({
            title: that.$element.data('subject'),
            text: that.$element.data('text'),
            url: that.$element.data('url'),
          })
          .then(function () {
            console.log('Successful share')
          }).catch(function (error) {
            console.log('Error sharing', error)
          });
      } else {
        that.openShareLinks($(e.currentTarget));
      }
    });
  }

  ShareButton.prototype.openShareLinks = function () {

    var that = this;

    var animation = anime.timeline({
        easing: 'linear',
        begin: function () {
          that.$shareLinks.show();
          that.$element.css({
            'min-width': that.$toggler.outerWidth() + 2, // + border
            'min-height': that.$toggler.outerHeight() + 2, // + border
            'position': 'relative'
          });
          that.$toggler.css('position', 'absolute');

          that.$element.addClass('is-open')
        }
      })
      .add({
        targets: this.$toggler[0],
        duration: durations.labelHide,
        opacity: [1, 0],
        complete: function () {
          that.$toggler.hide();
        }
      }).add({
        targets: this.$element[0],
        duration: durations.linkShow,
        paddingLeft: [0, '1rem'],
        paddingRight: [0, '1rem']
      });

    for (var i = 0; i < this.$shareLinks.length; i++) {
      animation.add({
          targets: this.$shareLinks[i],
          duration: durations.linkShow,
          offset: '-=' + durations.linkShow,
          paddingLeft: [0, '1rem'],
          paddingRight: [0, '1rem']
        })
        .add({
          targets: this.$shareLinks[i],
          duration: durations.linkShow,
          opacity: [0, 1]
        })
    }
  }

  $(document).ready(function () {

    $('.u-share-button').each(function (index, element) {
      var $element = $(element);
      if (!$element.data('share-button')) {
        var shareButtonObject = new ShareButton($element);
        $element.data('share-button', shareButtonObject);
      }
    });

    $(document).on('click', '.u-share-button__link--pdf', function () {
      window.print()
    })
  });

})();
(function() {

  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

  anime.easings['inOutQuad'] = function(t) { return t<.5 ? 2*t*t : -1+(4-2*t)*t };

  function Cards(element, options) {
      this.$element = $(element);
      this.slick = null;
      this.options = $.extend({}, {
        sliderOptions: {
          infinite: false,
          arrows: false,
          autoplay: false,
          variableWidth: true,
          adaptiveHeight: false
        }
      }, options);

      this.init();
  }

  Cards.prototype.init = function() {

    var that = this;

    this.slick = this.$element.slick(this.options.sliderOptions)
    .on("setPosition", function (event, slick) {

        // fix empty space after

        var $lastSlide = slick.$slides.last(),
            childPos = $lastSlide.offset(),
            parentPos = $lastSlide.parent().offset(),
            sliderWidth = slick.$slideTrack.parent().width(),
            trackTransform = parseInt(slick.$slideTrack.css("transform").split(",")[4], 10),
            childOffset = {
                top: childPos.top - parentPos.top,
                left: childPos.left - parentPos.left
            },
            minValue = sliderWidth - childOffset.left - $lastSlide.width() + 0;

            if (trackTransform < minValue) {
                slick.$slideTrack.css({
                    "transform": "translate3d(" + minValue +"px, 0, 0)",
                    "transition": "transform .5s"
                });
                slick.currentSlide = slick.currentSlide - 1;
            } 

    })
    .on('wheel', (function(e) {
      if ( e.originalEvent.deltaX !== 0 ) {
          e.preventDefault();
          if (e.originalEvent.deltaX >= 10) {
            $(this).slick('slickNext');
          } 
          if (e.originalEvent.deltaX <= -10) {
            $(this).slick('slickPrev');
          }
      }
    }));

    this.$element.on('click', '.u-cards__card', function(e) {
      var $card = $(e.target).closest('.u-cards__card');
      that.goTo($card);
      that.flipCard($card);
    });
  }

  Cards.prototype.goTo = function(card) {
    var $card = $(card);

    if($card.offset().left < 0 || ($(window).width() - $card.offset().left - $card.outerWidth()) < 0) {
      this.$element.slick('slickGoTo', $card.closest('.slick-slide').index());
    }
  }

  Cards.prototype.flipCard = function(card) {
    var $card = $(card),
        showBack = !$card.hasClass('is-flipped');

    $card.toggleClass('is-flipped');

    anime.timeline({
      easing: 'inOutQuad'
    })
    .add({
      targets: $card[0],
      duration: 600,
      translateX: showBack ? ["0%", "-100%"] : ["-100%", "0%"],
      rotateY: showBack ? ["0deg", "-180deg"] : ["-180deg", "0deg"]
    })
    .add({
      targets: $card.find('.u-cards__front')[0],
      duration: 600,
      offset: '-=600',
      opacity: !isIE11 ? [1, 1] : (showBack ? [1, 0] : [0, 1])
    })
    .add({
      targets: $card.find('.u-cards__back')[0],
      duration: 600,
      offset: '-=600',
      opacity: !isIE11 ? [1, 1] : (showBack ? [0, 1] : [1, 0])
    });
  };

  $(document).ready(function() {

      $('.u-cards').each(function(index, element) {
          $element = $(element);
          if(!$element.data('card')) {
              var cardObject = new Cards($element);
              $element.data('card', cardObject);
          }
      });

  });

})();
(function () {

  function CategoryTeaserSlider(element, options) {
      this.$element = $(element);
      this.slick = null;
      this.sliderOptions = $.extend({}, {
        infinite: false,
        arrows: true,
        autoplay: false,
        variableWidth: true,
        adaptiveHeight: false,
        appendArrows: this.$element.closest('.u-category-teaser-list__slider-wrapper').find('.u-category-teaser-list__arrows'),
        prevArrow: '<button class="u-category-teaser-list__prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62"><circle cx="31" cy="31" r="30"/><path d="M35,37.23,33.28,39,26,31.5,33.28,24,35,25.77,29.41,31.5Z" style="fill:currentColor"/></svg></button>',
        nextArrow: '<button class="u-category-teaser-list__next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62"><circle cx="31" cy="31" r="30"/><path d="M27,37.23,28.72,39,36,31.5,28.72,24,27,25.77l5.59,5.73Z" style="fill:currentColor"/></svg></button>'
      }, options);

      this.init();
  }

  CategoryTeaserSlider.prototype.init = function () {
    var that = this;

    this.slick = this.$element.slick(this.sliderOptions)
      .on("setPosition", function (event, slick) {

        // fix empty space after

        var $firstSlide = slick.$slides.first(),
            $lastSlide = slick.$slides.last(),
            firstChildPos = $firstSlide.offset(),
            childPos = $lastSlide.offset(),
            parentPos = $lastSlide.parent().offset(),
            sliderWidth = slick.$slideTrack.parent().width(),
            sliderCalculatedItemsWidth = childPos.left - firstChildPos.left + $lastSlide.width(),
            trackTransform = parseInt(slick.$slideTrack.css("transform").split(",")[4], 10),
            childOffset = {
                top: childPos.top - parentPos.top,
                left: childPos.left - parentPos.left
            },
            minValue = sliderWidth - childOffset.left - $lastSlide.width() + 0;

        console.table({ 'a': sliderWidth < $lastSlide.width() + childOffset.left, 'b': sliderWidth, 'c': $lastSlide.width() + childOffset.left })

        if (trackTransform < minValue) {
          slick.$slideTrack.css({
            "transform": "translate3d(" + (sliderWidth > sliderCalculatedItemsWidth ? 0 : minValue) +"px, 0, 0)",
            "transition": "transform .5s"
          });
          slick.currentSlide = slick.currentSlide - 1;
        }
      })
      .on('wheel', (function(e) {
        if ( e.originalEvent.deltaX !== 0 ) {
            e.preventDefault();
            if (e.originalEvent.deltaX >= 10) {
              $(this).slick('slickNext');
            } 
            if (e.originalEvent.deltaX <= -10) {
              $(this).slick('slickPrev');
            }
        }
      }));

    this.$element.on('click', '.u-image-slider__item', function(e) {
      var $sliderItem = $(e.target).closest('.u-image-slider__item');
      that.goTo($sliderItem);
    });

    this.$element.data('slider', this);
  }

  CategoryTeaserSlider.prototype.goTo = function(sliderItem) {
    var $sliderItem = $(sliderItem);

    if($sliderItem.offset().left < 0 || ($(window).width() - $sliderItem.offset().left - $sliderItem.outerWidth()) < 0) {
      this.$element.slick('slickGoTo', $sliderItem.closest('.slick-slide').index());
    }
  }

  $(document).ready(function() {

    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
    document.documentElement.style.setProperty('--admin-bar-height', $('html').css('margin-top'));
    $(window).resize(function() {
      setTimeout(function() {
        document.documentElement.style.setProperty('--admin-bar-height', $('html').css('margin-top'));
      })
    });

      $('.u-category-teaser-list__slider').each(function() {
        new CategoryTeaserSlider(this);
      })
  });

})();
(function() {

    $(document).ready(function(e, currentSlide) {

        var $slideItem = $(currentSlide).closest('.slick-slide').next(':not(.slick-cloned)');
        
        if($slideItem.length <= 0) {
            $slideItem = $(currentSlide).closest('.slick-track').children(':not(.slick-cloned)').eq(0);
        }

        var $slideHeadline = $slideItem.find('.u-teaser__headline'),
            $slidePicture = $slideItem.find('.u-teaser__image'),
            
            $nextTopicTeaser = $('.u-footer__next-topic-teaser');

            if($slideHeadline.html()) {
                
                $nextTopicTeaser.find('.u-footer__next-topic-headline').html($slideHeadline.html().replace(/\s*(<br \/>|<br\/>|<br>)\s*/, " "));
                $nextTopicTeaser.find('.u-footer__next-topic-image').html($slidePicture.html());
                $nextTopicTeaser.attr('href', $slideItem.find('.u-main-slider__item').data('page-url'));
                
                $nextTopicTeaser.show();
                
            }
    });

    inView('.u-footer__next-topic-teaser, .u-epaper-teaser .u-section__inner').on('enter', function(target) {
        var $target = $(target);

        if($target.hasClass('u-inview-complete')) return;

        anime.timeline({
            easing: 'linear',
            begin: function() {
                $target.addClass('u-inview-complete');
            }
        }).
        add({
            targets: target,
            // translateY: ['100%', 0],
            opacity: [0,1]
        });
    });
    $('.u-footer__next-topic-teaser, .u-epaper-teaser .u-section__inner').css({
        'opacity': 0
    });

})();
(function () {

    var $header = $('.u-header'),
        openClass = 'is-open',
        burgerIconLineSelector = '.u-menu-toggler__burger-icon-line',
        closeIconLineSelector = '.u-menu-toggler__close-icon-line',
        mainSliderSelector = '.u-main-slider',
        mainMenuSelector = '.u-main-menu',
        $mainMenu = $(mainMenuSelector),
        pageJumpedToModuleOnLoad = false;

    function startHeader() {
        $header.addClass('is-animation-running');
        $header.removeClass('is-not-initialized');
        // burger icon
        anime.timeline({
                easing: 'linear',
                complete: function () {
                    $header.removeClass('is-animation-running');
                }
            })

            // headline
            .add({
                targets: '.u-teaser__headline',
                duration: 333,
                //offset: '-=567',
                translateY: ["100%", 0],
                opacity: [0, 1]
            })

            // text
            .add({
                targets: '.u-teaser__text',
                duration: 666,
                offset: '-=300',
                translateY: ["100%", 0],
                opacity: [0, 1],
                lineHeight: ['5em', '1.5em'],
                marginBottom: ['-5em', '1em'],
                complete: function (t, e) {
                    $('.u-teaser__text').css({
                        'line-height': '',
                        'margin-bottom': ''
                    });
                },
            })

            // headline and lang switcher
            .add({
                targets: '.u-header__logo, .u-lang-switcher',
                duration: 333,
                offset: '-=666',
                opacity: [0, 1]
            })

            // controls
            .add({
                targets: '.u-controls',
                duration: 266,
                offset: '-=366',
                opacity: [0, 1]
            })
            .add({
                targets: '.u-main-slider__prev',
                duration: 633,
                offset: '-=366',
                translateX: ["-300%", 0],
                opacity: [0, 1]
            })
            .add({
                targets: '.u-main-slider__next',
                duration: 633,
                offset: '-=633',
                translateX: ["300%", 0],
                opacity: [0, 1]
            })


            // burger
            .add({
                targets: '.u-menu-toggler__icon',
                duration: 366,
                offset: '-=466',
                scaleX: [0, 1],
                begin: function () {
                    $(burgerIconLineSelector).show();
                }
            })
            .add({
                targets: burgerIconLineSelector + '-3',
                duration: 300,
                offset: '-=66',
                width: [0, 26.5],
                x: [31.8, 31.8],
                begin: function () {
                    $(burgerIconLineSelector).show();
                }
            })
            .add({
                targets: burgerIconLineSelector + '-2',
                duration: 300,
                offset: '-=200',
                width: [0, 26.5],
                x: [31.8, 31.8],
            })
            .add({
                targets: burgerIconLineSelector + '-1',
                duration: 300,
                offset: '-=200',
                width: [0, 26.5],
                x: [31.8, 31.8],
            })

            // see more
            .add({
                targets: '.u-header__see-more',
                duration: 233,
                offset: '-=333',
                opacity: [0, 1]
            })
            .add({
                targets: '.u-header__see-more-icon',
                duration: 400,
                offset: '-=200',
                translateY: ["-300%", 0],
                opacity: [0, 1]
            })
            .add({
                targets: '.u-header__see-more-label',
                duration: 400,
                offset: '-=400',
                translateY: ["300%", 0]
            })

            // photographer
            .add({
                targets: '.u-main-slider__photographer',
                duration: 400,
                offset: '-=400',
                opacity: [0, 1]
            });


        // see more button loop animation
        anime.timeline({
            easing: 'linear',
            loop: true
        }).
        add({
            targets: '.u-header__see-more-icon .u-svg-button__arrow',
            delay: 4000,
            duration: 300,
            translateY: ['0%', '15%']
        }).
        add({
            targets: '.u-header__see-more-icon .u-svg-button__arrow',
            duration: 300,
            translateY: ['15%', '0%']
        })
    }

    function openMenu() {
        $header.addClass('is-animation-running');

        anime.timeline({
                easing: 'easeOutExpo',
                complete: function () {
                    $header.removeClass('is-animation-running');
                },
                begin: function () {
                    $('body').addClass('has-open-menu');
                    $('.u-header__social-buttons .u-social-link').css('transform', 'scale(0)');
                    $('.u-header__bottom-menu-link, .u-header__bottom-menu-copyright').css('opacity', 0);
                }
            })

            // hide burger icon
            .add({
                targets: burgerIconLineSelector,
                duration: 333,
                width: [26.5, 0],
                x: [31.8, 31.8 + 26.5],
                complete: function () {
                    $(burgerIconLineSelector).hide();
                }
            })

            // show close icon
            .add({
                targets: closeIconLineSelector + '-1',
                duration: 200,
                width: [0, 25.1],
                x: [32.5, 32.5],
                begin: function () {
                    $(closeIconLineSelector).show();
                }
            })
            .add({
                targets: closeIconLineSelector + '-2',
                duration: 200,
                width: [0, 25.1],
                x: [32.5, 32.5]
            })

            // open menu container
            .add({
                targets: mainMenuSelector,
                duration: 733,
                offset: '-=400',
                width: [0, '100%'],
                begin: function () {
                    $mainMenu.show();
                }
            })

            .add({
                targets: '.u-main-menu__link',
                duration: 1,
                opacity: [0, 0],
                translateX: ['-10vw', '-10vw']
            })
            .add({
                duration: 1,
                complete: function () {
                    var $menuItems = $('.u-main-menu__list-item');
                    for (var i = 0; i < $menuItems.length; i++) {
                        animateMenuItemOpen($menuItems.eq(i), i * 200);
                    }
                }
            })
            .add({
                targets: '.u-header__social-button-text',
                duration: 166,
                opacity: [0, 1],
                begin: function () {
                    var $socialLinks = $('.u-header__social-buttons .u-social-link');
                    for (var i = 0; i < $socialLinks.length; i++) {
                        animateSocialIconOpen($socialLinks.eq(i), i * 166);
                    }
                }
            })
            .add({
                offset: '-=700',
                begin: function () {
                    var $bottomMenuItems = $('.u-header__bottom-menu-link, .u-header__bottom-menu-copyright');
                    for (var i = 0; i < $bottomMenuItems.length; i++) {
                        animateBottomMenuLinkOpen($bottomMenuItems.eq(i), i * 66);
                    }
                }
            })

        ;
    }

    function animateMenuItemOpen(menuItem, delay) {
        anime.timeline({
                easing: 'linear'
            })
            .add({
                targets: $(menuItem).find('.u-main-menu__link')[0],
                duration: 300,
                offset: '-=166',
                opacity: [0, 1],
                translateX: ['-10vw', '0vw']
            })
    }

    function animateSocialIconOpen(socialIcon, delay) {
        anime.timeline({
                easing: 'linear'
            })
            .add({
                targets: $(socialIcon)[0],
                duration: 166,
                delay: delay,
                scale: [0, 1]
            })
    }

    function animateBottomMenuLinkOpen(link, delay) {
        anime.timeline({
                easing: 'linear'
            })
            .add({
                targets: $(link)[0],
                duration: 333,
                delay: delay,
                opacity: [0, 1]
            })
    }



    function animateMenuItemClose(menuItem, delay) {
        anime.timeline({
                easing: 'linear'
            })
            .add({
                targets: $(menuItem).find('.u-main-menu__link')[0],
                duration: 300,
                offset: '-=166',
                opacity: [1, 0],
                translateX: ['0vw', '-10vw']
            })
    }

    function animateBottomMenuLinkClose(link, delay) {
        anime.timeline({
                easing: 'linear'
            })
            .add({
                targets: $(link)[0],
                duration: 333,
                delay: delay,
                opacity: [1, 0]
            })
    }

    function animateSocialIconClose(socialIcon, delay) {
        anime.timeline({
                easing: 'linear'
            })
            .add({
                targets: $(socialIcon)[0],
                duration: 166,
                delay: delay,
                scale: [1, 0]
            })
    }

    function closeMenu() {
        $header.addClass('is-animation-running');
        anime.timeline({
                easing: 'easeOutExpo',
                complete: function () {
                    $header.removeClass('is-animation-running');
                },
                begin: function () {
                    $('body').removeClass('has-open-menu');
                    $('.u-header__social-buttons, .u-header__bottom-menu').css('display', 'flex');
                }
            })

            .add({
                duration: 1,
                complete: function () {
                    var $menuItems = $('.u-main-menu__list-item');
                    for (var i = 0; i < $menuItems.length; i++) {
                        animateMenuItemClose($menuItems.eq(i), i * 200);
                    }
                }
            })

            // bottom menu links
            .add({
                // offset: '-=200',
                duration: 1,
                begin: function () {
                    var $bottomMenuItems = $('.u-header__bottom-menu-link, .u-header__bottom-menu-copyright').get().reverse();
                    for (var i = 0; i < $bottomMenuItems.length; i++) {
                        animateBottomMenuLinkClose($bottomMenuItems[i], i * 66);
                    }
                }
            })

            // social icons
            .add({
                // offset: '-=133',
                duration: 1,
                begin: function () {
                    var $socialLinks = $('.u-header__social-buttons .u-social-link').get().reverse();
                    for (var i = 0; i < $socialLinks.length; i++) {
                        animateSocialIconClose($socialLinks[i], i * 166);
                    }
                }
            })

            // close menu container
            .add({
                targets: mainMenuSelector,
                duration: 866,
                delay: 666,
                width: ['100%', 0]
            })

            // switch close icon to burger icon
            .add({
                targets: closeIconLineSelector + '-2',
                duration: 200,
                offset: '-=866',
                width: [25.1, 0],
                x: [32.5, 32.5 + 25.1],
            })
            .add({
                targets: closeIconLineSelector + '-1',
                duration: 200,
                width: [25.1, 0],
                x: [32.5, 32.5 + 25.1],
                complete: function () {
                    $(closeIconLineSelector).hide();
                }
            })
            .add({
                targets: burgerIconLineSelector + '-3',
                duration: 266,
                width: [0, 26.5],
                x: [31.8, 31.8],
                begin: function () {
                    $(burgerIconLineSelector).show();
                }
            })
            .add({
                targets: burgerIconLineSelector + '-2',
                duration: 266,
                offset: '-=66',
                width: [0, 26.5],
                x: [31.8, 31.8],
            })
            .add({
                targets: burgerIconLineSelector + '-1',
                duration: 266,
                offset: '-=66',
                width: [0, 26.5],
                x: [31.8, 31.8],
            });
    }


    $(function () {
        startHeader();
    });

    $('.u-menu-toggler').on('click', function () {

        if ($header.hasClass('is-animation-running')) return;


        var newState = !$header.hasClass(openClass);

        $header.toggleClass(openClass, newState);

        if (newState) {
            openMenu();
        } else {
            closeMenu();
        }

    });

    function scrollToCategory(catId, milliseconds) {
        var $element = $('.u-main-content > [data-cat="' + (catId || 1) + '"]');

        if ($element.length === 0) {
            $element = $('.u-main-content');
        }

        if ($element.length > 0) {
            var offTop = $element.offset().top - 50;
            $("html, body").stop().animate({ scrollTop: offTop }, milliseconds || 500, 'swing');
        }
    }

    function scrollToModule(modId) {
        if (modId === false) return;
        var $element = $('.u-main-content > [module-number="' + (modId || 1) + '"]');

        if ($element.length === 0) {
            $element = $('.u-main-content');
        }

        if ($element.length > 0) {
            var offTop = $element.offset().top - 50;
            $("html, body").stop().animate({ scrollTop: offTop }, 500, 'swing');
        }
    }

    $(document).on('click', '.u-header__see-more', function () {
        $('.u-main-slider .slick-active [data-see-more-url]').data('see-more-url');
        var catId = $('.u-main-slider .slick-active [data-cat-id]').data('cat-id');
        scrollToCategory(catId);
    });

    function getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return (false);
    }

    $(document).ready(function () {

        setTimeout(function() {
            if(pageJumpedToModuleOnLoad) return;
            pageJumpedToModuleOnLoad = true;
    
            var modId = getQueryVariable('mod');
            if(modId) {
                scrollToModule(modId);
            } else {
                var catId = getQueryVariable('category');
                if(catId) {
                    scrollToCategory(catId, 1);
                }
            }
        }, 300);

    });

    // function setIntroParallax() {

    //     var windowHeight = $(window).height(),
    //         scrollTop = $(window).scrollTop(),
    //         contentStartOffset = -1 * windowHeight / 2,
    //         scrollOffset = windowHeight - scrollTop;

    //     if(scrollOffset > 1) { // 1px is to fix rounding errors
    //         $('.u-main-content__wrapper').css({
    //             height: $('.u-main-content').outerHeight()
    //         });
    //         $('.u-main-content').css({
    //             transform: 'translateY(' + ((windowHeight + contentStartOffset) * (windowHeight - scrollTop) / windowHeight) + 'px)',
    //             position: 'fixed'
    //         });
    //     } else {
    //         $('.u-main-content__wrapper').css({
    //             height: ''
    //         });
    //         $('.u-main-content').css({
    //             transform: 'translateY(0px)',
    //             position: 'relative'
    //         });
    //     }
    // }

    // setIntroParallax();
    // var activeScrollAnimation = false;
    // $(window).scroll(function () {
    //     if(!activeScrollAnimation) {
    //         activeScrollAnimation = true;

    //         setTimeout(function() {
    //             activeScrollAnimation = false;
    //         });

    //         setIntroParallax();
    
    //         if($('.u-main-slider').hasClass('slick-initialized')) {
    //             $('.u-main-slider').slick('slickPause');
    //         } else {
    //             setTimeout(function() {
    //                 $('.u-main-slider').slick('slickPause');
    //             }, 1000);
    //         }
    //     }
    // });

})();
(function() {

    var durations = {
        buttonOpen: 900,
        imageOpen: 1000
    };

    function Illustration($element, options) {
        this.$element = $element;
        this.$popup = this.$element.find('.u-illustration__popup');
        this.$closeButton = this.$element.find('.u-illustration__popup-close-button');
        this.$popupImage = this.$element.find('.u-illustration__popup-image');
        this.$popupText = this.$element.find('.u-illustration__popup-text');
        this.$kicker = this.$element.find('.u-illustration__kicker');
        this.$separator = this.$element.find('.u-illustration__separator');
        this.$headline = this.$element.find('.u-illustration__headline');
        this.$text = this.$element.find('.u-illustration__text');
        this.$activeButton;
        this.blurEffect = null;
        this.options = $.extend({}, {

        }, options);

        this.init();
    }

    Illustration.prototype.init = function() {

        var that = this;
        
        var $hotspot = this.$element.find('.u-illustration__hotspot').filter(function(index, item) { return $(item).find('.u-illustration__hotspot-icon--bordered').length === 0 });
        if($hotspot.length > 0) {
            that.startButtonBlur($hotspot);
        }

        this.$element.on('click', '.u-illustration__hotspot', function(e) {
            that.$activeButton = $(e.currentTarget);
            that.openPopup($(e.currentTarget));
        });

        // this.$element.on('mouseenter', '.u-illustration__hotspot', function(e) {
        //     that.startButtonBlur(e.currentTarget);
        // });

        // this.$element.on('mouseleave', '.u-illustration__hotspot', function(e) {
        //     that.stopButtonBlur();
        // });

        this.$closeButton.on('click', function(e) {
            that.closePopup(that.$activeButton);
        });

        this.$element.find('.u-illustration__background').click(function(e) {
            var $container = that.$element.find('.u-illustration__background'),
                parentOffset = $container.offset(),
                relX = e.pageX - parentOffset.left,
                relY = e.pageY - parentOffset.top,
                percentageX = relX * 100 / $container.width(),
                percentageY = relY * 100 / $container.height();

            console.info('Hotspot Position:  T ' + (Math.round(percentageY * 100) / 100) + '  -   L ' + (Math.round(percentageX * 100) / 100));
        });

        this.$element.generateCustomCursor({
            $container: this.$element.find('.u-illustration__content-wrapper')
        });


    }

    Illustration.prototype.startButtonBlur = function(hotspot) {
        var $hotspot = $(hotspot),
            $backgroundWrapper = this.$element.find('.u-illustration__background'),
            $blurPicture,
            blurClass = 'u-illustration__blur-picture',
            $firstRoundedButton = $hotspot.find('.u-illustration__hotspot-icon--rounded'),
            coords,
            isEdgeBrowser = window.navigator.userAgent.indexOf('Edge/') >= 0,
            isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)),
            iPhone = navigator.userAgent.indexOf('iPhone') >= 0,
            iPad = navigator.userAgent.indexOf('iPad') >= 0;

        if($firstRoundedButton.length === 0 || isEdgeBrowser || isSafari || iPhone || iPad) return;

        coords = $firstRoundedButton.eq(0).parent()[0].style.left + ' ' + $firstRoundedButton.eq(0).parent()[0].style.top;
        $backgroundWrapper.find('.' + blurClass).remove();
        $blurPicture = $backgroundWrapper.children('.u-picture').clone().addClass(blurClass).appendTo($backgroundWrapper);

        this.blurEffect = anime.timeline({
            easing: 'linear',
            loop: true
        })
        .add({
            targets: $blurPicture[0],
            duration: 366,
            clipPath: ['circle(1.855% at ' + coords + ')', 'circle(8% at ' + coords + ')'],
            opacity: [1,1],
            begin: function(anim) {
                $('.' + blurClass).css({
                    '-webkit-clip-path': 'circle(1.855% at ' + coords + ')'
                });
                $('.' + blurClass).animate({
                    '-webkit-clip-path': 'circle(8% at ' + coords + ')'
                }, 366);
            },
        })
        .add({
            targets: $blurPicture[0],
            duration: 333,
            clipPath: ['circle(8% at ' + coords + ')', 'circle(10% at ' + coords + ')'],
            opacity: [1,0]
        })
        .add({
            targets: $blurPicture[0],
            duration: 1000
        });

        /**
        * 
        *
        *
        */

        // var css = '@keyframes bubble-blur { 0% { -webkit-clip-path: circle(1.855% at ' + coords + '); webkit-clip-path: circle(1.855% at ' + coords + '); opacity: 0; } 1% { opacity: 1; } 22% { -webkit-clip-path: circle(8% at ' + coords + '); webkit-clip-path: circle(8% at ' + coords + '); opacity: 1; } 41% { -webkit-clip-path: circle(10% at ' + coords + '); webkit-clip-path: circle(10% at ' + coords + '); opacity: 0; } 100% { opacity: 0; }} .u-illustration__blur-picture { animation: bubble-blur 1.699s infinite; }',
        //     head = document.head || document.getElementsByTagName('head')[0],
        //     style = document.createElement('style');

        // head.appendChild(style);

        // style.type = 'text/css';
        // style.appendChild(document.createTextNode(css));
    }

    Illustration.prototype.stopButtonBlur = function() {
        this.blurEffect.pause();
        this.blurEffect.seek(0);
    }

    Illustration.prototype.openPopup = function($button) {
        var that = this,
            data = $button.data(),
            $clonedImage;

        if(that.$element.hasClass('is-animation-running')) return;

        that.$element.addClass('is-animation-running');

        if(that.$element.offset().top < $(window).scrollTop()) {
            $("html, body").stop().animate({ scrollTop: that.$element.offset().top }, 500, 'swing');
        }

        that.$popup.show();
        // delete and recreate for IE11 / ofi bugfix
        that.$popupImage.find('img').remove();
        var $img = $('<img src=' + data.image + '/>');
        that.$popupImage.append($img);
        that.$kicker.html(data.kicker);
        if(data.kicker.trim()) {
            that.$kicker.show();
            that.$separator.show();
        } else {
            that.$kicker.hide();
            that.$separator.hide();
        }

        that.$headline.html(data.headline);
        if(data.headline.trim()) {
            that.$headline.show();
        } else {
            that.$headline.hide();
        }
        
        that.$text.html(data.text);
        if(data.text.trim()) {
            that.$text.show();
        } else {
            that.$text.hide();
        }

        $clonedImage = that.$popupImage.clone().addClass('is-moving').insertAfter(that.$popupImage);
        that.$popupImage.css('opacity', 0);
        that.$popupText.addClass('is-moving');

        // imageTargetPosition

        var popupOffset = that.$popup.offset(),
            popupWidth = that.$popup.outerWidth(),
            popupHeight = that.$popup.outerHeight(),
            imageOffset = that.$popupImage.offset(),
            imageWidth = that.$popupImage.outerWidth(),
            imageHeight = that.$popupImage.outerHeight(),
            
            imageTargetPosition = {
                left: (imageOffset.left - popupOffset.left + imageWidth / 2) * 100 / popupWidth,
                top: (imageOffset.top - popupOffset.top + imageHeight / 2) * 100 / popupHeight
            };

        anime.timeline({
            easing: 'easeOutExpo',
            begin: function(anim) {
                
            },
            complete: function () {
                that.$element.removeClass('is-animation-running');
                that.$element.find('.u-illustration__blur-picture').css('visibility', 'hidden');
            }
        })
        // background
        .add({
            targets: that.$popup[0],
            duration: 600,
            backgroundColor: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.8)']
        })
        // text
        .add({
            targets: that.$popupText[0],
            duration: 600,
            offset: '-=600',
            opacity: [0, 1]
        })
        // image
        .add({
            targets: $clonedImage[0],
            duration: 600,
            offset: '-=600',
            begin: function(anim) {
            },
            complete: function() {
                $clonedImage.remove();
                that.$popupImage.css('opacity', 1);
            },
            width: [$button.outerWidth(), that.$popupImage.outerWidth()],
            height: [$button.outerHeight(), that.$popupImage.outerHeight()],
            left: [$button[0].style.left, imageTargetPosition.left],
            top: [$button[0].style.top, imageTargetPosition.top],
        })
        // hotspots
        .add({
            targets: that.$element.find('.u-illustration__hotspots')[0],
            offset: '-=600',
            duration: 300,
            opacity: [1, 0]
        })
        // close button bg
        .add({
            targets: that.$closeButton.find('.u-illustration__popup-close-icon-background')[0],
            duration: 600,
            offset: '-=300',
            opacity: [0, 1],
            complete: function() {
                that.$popupText.removeClass('is-moving');
            }
        })
        // separator
        .add({
            targets: that.$separator[0],
            offset: '-=166',
            duration: 333,
            scaleX: [0, 1]
        })
    }

    Illustration.prototype.closePopup = function($button) {
        var that = this,
            $clonedImage;

        if(that.$element.hasClass('is-animation-running')) return;

        that.$element.addClass('is-animation-running');

        $clonedImage = that.$popupImage.clone().addClass('is-moving').insertAfter(that.$popupImage);
        that.$popupImage.css('opacity', 0);
        that.$popupText.addClass('is-moving');

        // imageTargetPosition

        var popupOffset = that.$popup.offset(),
            popupWidth = that.$popup.outerWidth(),
            popupHeight = that.$popup.outerHeight(),
            imageOffset = that.$popupImage.offset(),
            imageWidth = that.$popupImage.outerWidth(),
            imageHeight = that.$popupImage.outerHeight(),

            imageTargetPosition = {
                left: (imageOffset.left - popupOffset.left + imageWidth / 2) * 100 / popupWidth,
                top: (imageOffset.top - popupOffset.top + imageHeight / 2) * 100 / popupHeight
            };

        anime.timeline({
            easing: 'easeOutExpo',
            begin: function() {
                that.$element.find('.u-illustration__blur-picture').css('visibility', 'visible');
            },
            complete: function(anim) {
                that.$popupText.removeClass('is-moving');
                that.$popup.hide();
                that.$element.removeClass('is-animation-running');
            }
        })
        // separator
        .add({
            targets: that.$separator[0],
            duration: 233,
            scaleX: [1, 0]
        })

        // close button bg
        .add({
            targets: that.$closeButton.find('.u-illustration__popup-close-icon-background')[0],
            duration: 600,
            offset: '-=200',
            opacity: [1, 0]
        })
        // hotspots
        .add({
            targets: that.$element.find('.u-illustration__hotspots')[0],
            duration: 300,
            offset: '-=600',
            opacity: [0, 1]
        })
        // image
        .add({
            targets: $clonedImage[0],
            duration: 600,
            offset: '-=300',
            opacity: [1, 0],
            complete: function() {
                $clonedImage.remove();
                that.$popupImage.css('opacity', 1);
            },
            width: [that.$popupImage.outerWidth(), $button.outerWidth()],
            height: [that.$popupImage.outerHeight(), $button.outerHeight()],
            left: [imageTargetPosition.left, $button[0].style.left],
            top: [imageTargetPosition.top, $button[0].style.top],
        })
        // text
        .add({
            targets: that.$popupText[0],
            duration: 600,
            offset: '-=600',
            opacity: [1, 0]
        })
        // background
        .add({
            targets: that.$popup[0],
            duration: 600,
            offset: '-=600',
            backgroundColor: ['rgba(0, 0, 0, 0.8)', 'rgba(0, 0, 0, 0)']
        })

    }

    $(document).ready(function() {

        $('.u-illustration').each(function(index, element) {
            $element = $(element);
            if(!$element.data('illustration')) {
                var illustrionObject = new Illustration($element);
                $element.data('illustration', illustrionObject);
            }
        });

        var inviewIllustration = inView('.u-illustration').on('enter', function(target) {

            var $target = $(target);

            if($target.hasClass('u-inview-complete')) return;

            // anime.timeline({
            //     easing: 'linear',
            //     duration: 2000,
            //     begin: function() {
            //         $target.addClass('u-inview-complete');
            //     }
            // }).
            // add({
            //     targets: target,
            //     opacity: [0,1],
            // });
        });
        destroyInViewOnContentDestroy(inviewIllustration);
    });

})();
(function () {

  function ImageSlider(element, options) {
      this.$element = $(element);
      this.slick = null;
      this.sliderOptions = $.extend({}, {
        infinite: false,
        arrows: true,
        autoplay: false,
        adaptiveHeight: false,
        variableWidth: true,
        
        centerMode: true,
        centerPadding: '40px',
        prevArrow:'<button class="u-image-slider__prev u-svg-button"><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 62 62" style="enable-background:new 0 0 62 62;" xml:space="preserve"><circle class="u-svg-button__background" cx="31" cy="31" r="30" /><path class="u-svg-button__arrow" d="M35,37.2L33.3,39L26,31.5l7.3-7.5l1.7,1.8l-5.6,5.7L35,37.2z" /></svg></button>',
        nextArrow: '<button class="u-image-slider__next u-svg-button"><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 62 62" style="enable-background:new 0 0 62 62;" xml:space="preserve"><circle class="u-svg-button__background" cx="31" cy="31" r="30"/><path class="u-svg-button__border" d="M31,61.5C14.2,61.5,0.5,47.8,0.5,31C0.5,14.2,14.2,0.5,31,0.5c16.8,0,30.5,13.7,30.5,30.5	C61.5,47.8,47.8,61.5,31,61.5z M31,1.5C14.7,1.5,1.5,14.7,1.5,31S14.7,60.5,31,60.5S60.5,47.3,60.5,31S47.3,1.5,31,1.5z"/><path class="u-svg-button__arrow" d="M27,37.2l1.7,1.8l7.3-7.5L28.7,24L27,25.8l5.6,5.7L27,37.2z"/></svg></button>'
      }, options);

      this.init();
  }

  ImageSlider.prototype.init = function () {
    var that = this;

    this.slick = this.$element.slick(this.sliderOptions)
      .on("setPosition", function (event, slick) {

        // fix empty space after

        // var $lastSlide = slick.$slides.last(),
        //     childPos = $lastSlide.offset(),
        //     parentPos = $lastSlide.parent().offset(),
        //     sliderWidth = slick.$slideTrack.parent().width(),
        //     trackTransform = parseInt(slick.$slideTrack.css("transform").split(",")[4], 10),
        //     childOffset = {
        //         top: childPos.top - parentPos.top,
        //         left: childPos.left - parentPos.left
        //     },
        //     minValue = sliderWidth - childOffset.left - $lastSlide.width() + 0;

        // if (trackTransform < minValue) {
        //   slick.$slideTrack.css({
        //     "transform": "translate3d(" + minValue +"px, 0, 0)",
        //     "transition": "transform .5s"
        //   });
        //   slick.currentSlide = slick.currentSlide - 1;
        // }
      })
      .on('wheel', (function(e) {
        if ( e.originalEvent.deltaX !== 0 ) {
            e.preventDefault();
            if (e.originalEvent.deltaX >= 10) {
              $(this).slick('slickNext');
            } 
            if (e.originalEvent.deltaX <= -10) {
              $(this).slick('slickPrev');
            }
        }
      }));

    this.$element.on('click', '.u-image-slider__item', function(e) {
      var $sliderItem = $(e.target).closest('.u-image-slider__item');
      that.goTo($sliderItem);
    });

    this.$element.data('slider', this);
  }

  ImageSlider.prototype.goTo = function(sliderItem) {
    var $sliderItem = $(sliderItem);

    if($sliderItem.offset().left < 0 || ($(window).width() - $sliderItem.offset().left - $sliderItem.outerWidth()) < 0) {
      this.$element.slick('slickGoTo', $sliderItem.closest('.slick-slide').index());
    }
  }

  $(document).ready(function() {

    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
    document.documentElement.style.setProperty('--admin-bar-height', $('html').css('margin-top'));
    $(window).resize(function() {
      setTimeout(function() {
        document.documentElement.style.setProperty('--admin-bar-height', $('html').css('margin-top'));
      })
    });

      $('.u-image-slider').each(function() {
        new ImageSlider(this);
      })
  });

})();
(function () {

  function MainSlider(element, options) {
      this.$element = $(element);
      this.slick = null;
      this.sliderOptions = $.extend({}, {
        infinite: true,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 4000,
        appendArrows: '.u-controls',
        prevArrow:'<button class="u-main-slider__prev u-svg-button u-svg-button--style-bordered"><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 62 62" style="enable-background:new 0 0 62 62;" xml:space="preserve"><path class="u-svg-button__background" d="M31,61.5C14.2,61.5,0.5,47.8,0.5,31C0.5,14.2,14.2,0.5,31,0.5c16.8,0,30.5,13.7,30.5,30.5 C61.5,47.8,47.8,61.5,31,61.5z M31,1.5C14.7,1.5,1.5,14.7,1.5,31S14.7,60.5,31,60.5S60.5,47.3,60.5,31S47.3,1.5,31,1.5z"/><path class="u-svg-button__arrow" d="M35,37.2L33.3,39L26,31.5l7.3-7.5l1.7,1.8l-5.6,5.7L35,37.2z"/></svg></button>',
        nextArrow: '<button class="u-main-slider__next u-svg-button"><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 62 62" style="enable-background:new 0 0 62 62;" xml:space="preserve"><circle class="u-svg-button__background" cx="31" cy="31" r="30"/><path class="u-svg-button__border" d="M31,61.5C14.2,61.5,0.5,47.8,0.5,31C0.5,14.2,14.2,0.5,31,0.5c16.8,0,30.5,13.7,30.5,30.5	C61.5,47.8,47.8,61.5,31,61.5z M31,1.5C14.7,1.5,1.5,14.7,1.5,31S14.7,60.5,31,60.5S60.5,47.3,60.5,31S47.3,1.5,31,1.5z"/><path class="u-svg-button__arrow" d="M27,37.2l1.7,1.8l7.3-7.5L28.7,24L27,25.8l5.6,5.7L27,37.2z"/></svg></button>'
      }, options);

      this.init();
  }

  MainSlider.prototype.init = function () {

    this.slick = this.$element.slick(this.sliderOptions)
      .on('wheel', (function(e) {
        if ( e.originalEvent.deltaX !== 0 ) {
            e.preventDefault();
            if (e.originalEvent.deltaX >= 10) {
              $(this).slick('slickNext');
            } 
            if (e.originalEvent.deltaX <= -10) {
              $(this).slick('slickPrev');
            }
        }
      }));

    this.$element.data('slider', this);
  }

  $(document).ready(function() {
      $('.u-main-slider').each(function() {
        new MainSlider(this);
      })
  });

})();
(function () {

    function Panorama(element, options) {
        this.$element = $(element);
        this.$imageWrapper = this.$element.find(".u-panorama__image-wrapper");
        this.viewer = null;

        this.options = $.extend({}, {

        }, options);

        this.init();
    }

    Panorama.prototype.init = function () {

        var that = this;

        if ($('.u-header').hasClass('is-animation-running')) {
            setTimeout(function () {
                that.init();
            }, 100);
            return;
        }

        var container = this.$element.find('.u-panorama__image')[0],
            src = this.$element.data('src'),
            longitude = this.$element.data('longitude'),
            latitude = this.$element.data('latitude'),
            zoom = this.$element.data('zoom');

        this.viewer = new PhotoSphereViewer({
            container: container,
            panorama: src,
            default_fov: zoom || 60,
            default_long: longitude || 0,
            default_lat: latitude || 0,
            navbar: 'gyroscope',
            anim_speed: '0.5rpm',
            time_anim: false,
            touchmove_two_fingers: true,
            onready: function () {
                PSV.toggleDeviceOrientation();
            }
        });

        this.$element.generateCustomCursor({
            $container: this.$element.find('.u-panorama__image-wrapper'),
            forceGrabbing: true
        });
    }

    $(document).ready(function () {

        $('.u-panorama').each(function (index, element) {
            $element = $(element);
            if (!$element.data('panorama')) {
                var panoramaObject = new Panorama($element);
                $element.data('panorama', panoramaObject);
            }
        });

    });

})();
(function() {

    var durations = {
        buttonOpen: 900,
        imageOpen: 1000
    };

    function ReadMore($element, options) {
        this.$element = $element;
        this.$topToolbar = this.$element.find('.u-read-more__top-toolbar');
        this.$bottomToolbar = this.$element.find('.u-read-more__bottom-toolbar');
        this.$readMoreButton = this.$element.find('.u-read-more__read-more-button');
        this.$readLessButton = this.$element.find('.u-read-more__read-less-button');
        this.$modules = this.$element.find('.u-read-more__modules');
        this.isOpen = false;
        this.options = $.extend({}, {

        }, options);

        this.init();
    }

    ReadMore.prototype.init = function() {

        var that = this;

        this.$readMoreButton.click(function(e) {
            that.isOpen = true;
            that.open();
        });

        this.$readLessButton.click(function(e) {
            that.isOpen = true;
            that.close();
        });

    }

    ReadMore.prototype.open = function() {
        this.$topToolbar.hide();
        this.$bottomToolbar.show();
        this.$modules.show();
    }

    ReadMore.prototype.close = function() {
        this.$topToolbar.show();
        this.$bottomToolbar.hide();
        this.$modules.hide();
    }

    $(document).ready(function() {

        $('.u-module--read-more-content').each(function(index, element) {
            $element = $(element);
            if(!$element.data('read-more')) {
                var readMoreObject = new ReadMore($element);
                $element.data('read-more', readMoreObject);
            }
        });

    });

})();
(function () {

    function YoutubePlayer(element, options) {
        this.$element = $(element);
        this.playerId = this.$element.find('iframe').attr('id');
        this.player = null;

        this.options = $.extend({}, {

        }, options);

        this.init();
    }

    YoutubePlayer.prototype.init = function () {
        var that = this;

        this.player = new YT.Player(this.playerId, {});
    }

    $(document).ready(function () {

        $('.u-youtube-player').each(function (index, element) {
            var $element = $(element);
            if (!$element.data('youtube')) {
                var $iframe = $element.find('iframe');
                $iframe.on('load', function() {
                    var playerObject = new YoutubePlayer($element);
                    $element.data('youtube', playerObject);
                });
            }
        });

        var inviewYoutubePlayer = inView('.u-youtube-player').on('enter', function(target) {
            var $target = $(target),
                playerObject = $target.data('youtube');

            if(playerObject && playerObject.player && playerObject.player.playVideo) {
                playerObject.player.playVideo()
            }
        }).on('exit', function(target) {
            var $target = $(target),
                playerObject = $target.data('youtube');

            if(playerObject && playerObject.player && playerObject.player.pauseVideo && playerObject.player.isMuted()) {
                playerObject.player.pauseVideo()
            }
        });
        destroyInViewOnContentDestroy(inviewYoutubePlayer);

    });

})();